export default `* {
  font-size: 14px;
}

#nice h1 span.content {
  font-size: 18px;
}
#nice h2 span.content {
  font-size: 18px;
}
#nice h3 span.content {
  font-size: 16px;
}
#nice {
  max-width: 950px;
  margin: 0 auto;
  padding: 15px;
  color: #000;
  letter-spacing: 1.1px;
  word-break: break-word;
  word-wrap: break-word;
  text-align: left;
}

::marker {
  font-family: "HarmonyOS Sans";
}

/*文中段落*/
#nice p {
  color: #333;
  margin: 0px 10px;
  font-size: 14px;
  font-family: Optima-Regular, Optima, PingFangSC-light, PingFangTC-light,
    "PingFang SC", Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  word-spacing: 2px;
}

#nice li section p {
  margin: 0;
  padding: 0px 0px;
}
#nice h3 span.suffix,
#nice h4 span.suffix,
#nice h5 span.suffix,
#nice h6 span.suffix {
  display: inline-block;
  display: inline-block;
  margin-left: 10px;
  height: 24px;
  width: 24px;
  vertical-align: top;
  transform: translate(-7px, -2px);
}

#nice h3 span.suffix {
  background: url("http://cdn.phycat.cn/img/mdicon/green/h3_icon.svg");
}
#nice h4 span.suffix {
  background: url("http://cdn.phycat.cn/img/mdicon/green/h4_icon.svg");
}

#nice h5 span.suffix {
  background: url("http://cdn.phycat.cn/img/mdicon/green/h5_icon.svg");
}

#nice h6 span.suffix {
  background: url("http://cdn.phycat.cn/img/mdicon/green/h6_icon.svg");
}

#nice h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "HarmonyOS Sans";
}

/* 一级标题 */

#nice h1 {
  text-align: center;
  font-size: 20px;
}

/* 二级标题 */

#nice h2 {
  line-height: 1.4;
  width: fit-content;
  font-weight: bold;
  margin: 15px 0;
  padding: 1px 5px 2px 5px;
  border-radius: 4px;
  background-color: #f6ae77;
  background: linear-gradient(
    to right,
    #3db8bf,
    #80f7ff
  );
  background-size: 200% 100%;
  background-position: 0% 0%;
  transition: all ease-in-out 0.1s;
}
#nice h2.md-heading a {
  text-decoration: underline;
  border-bottom: 0;
}
#nice h2:hover {
  background-position: -100% -100%;
  transition: all ease-in-out 0.1s;
}

/* 三级标题 */
#nice h3 {
  width: fit-content;
  margin: 15px 0;
  font-size: 16px;
  text-align: left;
  padding-left: 10px;
  border-left: 4px solid #3db8bf;
}

/* 三级标题内容 */
#nice h3 span {
  border-bottom: 2px hidden #3db8bf;
}

#nice h3 span:hover {
  border-bottom: 2px solid #3db8bf;
  transition: all linear 0.1s;
}
#nice h4 {
  margin: 10px 0;
  font-size: 16px;
  text-align: left;
}

#nice h4::before {
  content: "";
  margin-right: 7px;
  display: inline-block;
  background-color: #3db8bf;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: #7aeaf0 1px solid;
  vertical-align: inherit;
}

#nice h5 {
  margin: 15px 0;
  font-size: 16px;
  text-align: left;
}

#nice h5::before {
  content: "";
  margin-right: 7px;
  display: inline-block;
  background-color: #ffffff;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: #3db8bf 2px solid;
  vertical-align: inherit;
}

#nice h6 {
  margin: 15px 0;
  font-size: 16px;
  text-align: left;
}

#nice h6::before {
  content: "-";
  color: #3db8bf;
  margin-right: 7px;
  display: inline-block;
  vertical-align: inherit;
}

/* 列表 */
::marker {
  /* font-weight: bold; */
  color: #626161;
}

li.md-list-item {
  margin: 0.4rem 0;
}

#nice ol {
  margin-left: 2px;
  padding-left: 12px;
  margin-bottom: 0px;
  margin-top: 0px;
}

#nice ul {
  list-style-type: disc;
  margin-bottom: 0px;
  margin-top: 0px;
}

#nice ul ul {
  list-style-type: circle;
}

#nice ul ul ul {
  list-style-type: square;
}

#nice ol {
  padding-left: 27px;
  list-style-type: decimal;
}
#nice ol ol {
  list-style-type: lower-alpha;
}
#nice ol ol ol {
  list-style-type: lower-roman;
}

#nice li section {
  font-family: Optima-Regular, Optima, PingFangSC-light, PingFangTC-light,
    Cambria, Cochin, Georgia, Times, serif;
  color: #333;
  margin: 0px 6px;
  word-spacing: 2px;
  line-height: 2.5;
}

#nice li {
  position: relative;
}

/** 表格内的单元格*/
#nice table tr th,
#nice table tr td {
  font-size: 14px;
  color: #000;
  border: none;
}
#nice table tr {
  border-top: none;
}

#nice table tr:nth-child(2n) {
  background-color: inherit;
}
#nice table tr th {
  font-weight: bold;
  background-color: #fafafa;
}
#nice .footnotes {
  padding: 10px;
  font-size: 14px;
  border-radius: 6px;
  border: 0.8px solid #ffc000;
}

#nice table.md-table {
  overflow: hidden;
}
#nice table thead {
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}
#nice table tbody {
  border-bottom: 1px solid #ffffff;
}

/* 引用 */

#nice blockquote {
  background-color: #ffffff;
  margin-left: 12px;
  padding: 12px;
  border: 1px solid #d7d9e2;
  border-left-color: #3db8bf;
  border-left-width: 4px;
  border-radius: 4px;
  line-height: 26px;
}

#nice blockquote p {
  color: #000;
}

/* 链接 */
#nice a {
  color: #000;
  border-bottom: 1px solid #3db8bf;
  text-decoration: none;
  transform: all linear 0.1s;
}

#nice a:hover:not(.md-toc-inner) {
  /* font-weight: bolder; */
  color: #3db8bf;
  border-bottom: 1px solid #3db8bf;
  transform: all linear 0.1s;
}

#nice p a::before {
  content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024'%3E%3Cpath  d='M477.934459 330.486594A50.844091 50.844091 0 0 1 406.752731 258.796425L512 152.532274a254.220457 254.220457 0 0 1 359.467726 359.467726L762.66137 618.772592a50.844091 50.844091 0 1 1-71.690168-71.690169l106.772591-106.772592a152.532274 152.532274 0 0 0-215.578947-215.578947z m70.164846 361.501489A50.844091 50.844091 0 1 1 619.789474 762.66137l-107.281033 107.281033A254.220457 254.220457 0 0 1 152.532274 512L259.813307 406.752731a50.844091 50.844091 0 1 1 72.19861 69.656405l-107.789474 107.281033a152.532274 152.532274 0 0 0 215.578947 215.578947z m-126.601788-16.77855a50.844091 50.844091 0 1 1-71.690168-71.690169l251.678252-251.678252a50.844091 50.844091 0 0 1 71.690169 71.690169z'/%3E%3C/svg%3E");
  color: #3db8bf;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.2em;
  vertical-align: sub;
}

#nice sup a::before {
  content: none;
}
/* 加粗 */
#nice strong {
  color: #000;
  font-weight: bold;
}

#nice strong::after {
}

/* 斜体 */
#nice em {
  font-style: italic;
  color: #000;
}
/* 高亮 */
#nice mark {
  font-weight: bolder;
  color: #000;
  background: #fbe598;
}
/* 加粗斜体 */
#nice em strong {
  color: #595959;
}

/* 删除线 */
#nice del {
  color: #595959;
}

/* 分隔线*/
#nice hr {
  height: 1px;
  padding: 0;
  border: none;
  border-top: 2px solid #3db8bf;
}

/* 图片*/
#nice img {
  border-radius: 6px;
  display: block;
  margin: 20px auto;
  object-fit: contain;
}

/* 图片描述文字 */
#nice figcaption {
  display: block;
  font-size: 13px;
  color: #595959;
}

/* 行内代码 */
#nice p code {
  padding: 0px 3px 0px 7px;
  color: #143526;
  background: #7aeaf077;
  border-radius: 3px;
  font-family: Operator Mono, Consolas, Monaco, Menlo, monospace;
  letter-spacing: 0.5px;
}
#nice li code {
  color: #595959;
}

/*公式块*/
#nice .block-equation {
  overflow-x: auto;
  overflow-y: hidden;
}

/* 代码块 去除阴影*/
#nice .custom {
  border-radius: 5px;
  box-shadow: none;
}
/** 表格内的单元格*/
#nice table tr th,
#nice table tr td {
  font-family: "HarmonyOS Sans";
  font-size: 13px;
  color: #222;
}

#nice .footnotes {
  padding: 10px;
  font-size: 14px;
  border-radius: 6px;
  border: 0.8px solid #3db8bf;
}

#nice table.md-table {
  overflow: hidden;
}
#nice table thead {
  border-top: 1px solid #dedddd;
  border-bottom: 1px solid #dedddd;
}
#nice table tbody {
  border-bottom: 1px solid #dedddd;
}

/* 脚注文字 */
#nice .footnote-word {
  font-weight: normal;
  color: #595959;
}

/* 脚注上标 */
#nice .footnote-ref {
  font-weight: normal;
  color: #595959;
}

/*脚注链接样式*/
#nice .footnote-item em {
  font-size: 14px;
  color: #595959;
  display: block;
  background: none;
}

/* 行间公式*/
#nice .block-equation svg {
}

/* 行内公式
    */
#nice .inline-equation svg {
}

/* 目录 */

.md-toc {
  font-family: "HarmonyOS_Sans_SC";
}
.md-tooltip-hide > span {
  display: none;
}
.table-of-contents:before {
  font-family: "HarmonyOS Sans";
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: center;
  content: "目录";
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.table-of-contents {
  font-family: Optima-Regular, Optima, PingFangSC-light, PingFangTC-light,
    "PingFang SC", Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  padding: 20px 0;
  margin: 0 20px;
  background-color: #7aeaf018;
  border: 2px solid #089ba3;
  border-radius: 5px;
}
.table-of-contents li {
  font-size: 14px;
  line-height: 1.8em;
  display: block;
  color: #333;
}
.table-of-contents a {
  text-decoration: none !important;
}

/* "参考资料"四个字 */
#nice .footnotes-sep:before {
  content: "文中链接";
}
`;
