import React from "react";
import ReactDOM from "react-dom";

import Lib from "./Lib";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <Lib
    useImageHosting={{
      url: "https://www.picgo.net/api/1/upload",
      name: "PicGo图床",
      isSmmsOpen: false,
      isQiniuyunOpen: true,
      isAliyunOpen: false,
    }}
    defaultTitle="物理猫微信编辑器"
  />,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
